import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Badge, Carousel, Breadcrumb, Input, Typography, Descriptions, Avatar, List, Skeleton, Switch, Tag} from "antd";
// import { ItemTareaPendiente } from "pages/tareas-pendientes/componentes/common";
// import { useAtom, useSetAtom } from "jotai";
// import {
// 	tareaPendienteSeleccionadaAtom,
// 	tareaSeleccionadaIdAtom,
// } from "pages/tareas-pendientes/TareasPendientesStorage";
// import { useListarTareasPendientes } from "pages/tareas-pendientes/componentes/common/hooks";
import LoginService from 'services/LoginService';
import { PublicacionService } from "services/comunicacion-cultura";
import { UserOutlined, StarOutlined, LikeOutlined, MessageOutlined} from "@ant-design/icons";
import {
	HomeOutlined
} from "@ant-design/icons"
import moment from "moment";
import "moment/locale/es";
const { Text } = Typography;

const ListaPublicaciones = () => {
	moment.locale('es');
	const [loading, setLoading] = useState(false);
	const [publicaciones, setPublicaciones] = useState([]);
	const avatarSize = 100;
	// const [tareaSeleccionadaId, setTareaSeleccionadaId] = useAtom(tareaSeleccionadaIdAtom);
	// const setTareaPendienteSeleccionada = useSetAtom(tareaPendienteSeleccionadaAtom);
	// const { tareasPendientes, onSearchTareasPendientes } = useListarTareasPendientes();

	// const onSeleccionar = (id) => {
	// 	if (id !== tareaSeleccionadaId) {
	// 		setTareaSeleccionadaId(id);
	// 	}
	// };

	useEffect(() => {
		const getPublicaciones = async () => {
			const respuesta = await PublicacionService.listar();
			setPublicaciones(respuesta);
			console.log("listaaaa::::", respuesta);
		};

		getPublicaciones();
	}, []);

	const IconText = ({ icon, text }) => (
		<span>
			{React.createElement(icon, {
			style: {
				marginRight: 8,
			},
			})}
			{text}
		</span>
		);
	return (
		<div style={{ padding: "25px" }}>
			<List
				itemLayout="vertical"
				size="large"
				dataSource={publicaciones}
				renderItem={(item) => (
					<List.Item
						key={item.title}
						actions={
							!loading
								? [
										<IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
										<IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
										<IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
								  ]
								: undefined
						}
					>
						<Skeleton loading={loading} active avatar>
							<List.Item style={{ borderBottom: "none", padding: "0" }}>
								<div style={{ display: "flex", alignItems: "flex-start" }}>
									<div
										style={{
											//width: "32px", // Tamaño fijo del avatar
											height: "32px",
											overflow: "hidden", // Oculta el contenido desbordado
											//borderRadius: "50%", // Forma circular
											marginRight: "16px", // Espaciado entre avatar y contenido
										}}
									>
										<img
											src={
												LoginService.getUsuarioAutenticado().empresaSeleccionada
													.urlImagenLogotipo
											}
											alt="Logotipo de la empresa"
											style={{
												width: "100%",
												height: "100%",
												objectFit: "cover", // Ajusta correctamente la imagen al contenedor
											}}
										/>
									</div>
									<div style={{ flex: 1 }}>
										{" "}
										{/* Ajuste flexible para alinear el contenido */}
										<h4 style={{ margin: 0 }}>
											{LoginService.getUsuarioAutenticado().empresaSeleccionada.razonSocial}
										</h4>
										<p style={{ margin: 0, color: "rgba(0, 0, 0, 0.45)" }}>
											{`${moment(item.usuarioRegistro.fechaRegistro)
												.locale("es")
												.format("D [de] MMMM [de] YYYY, [a las] hh:mm A")}`}
										</p>
									</div>
									<div>
										{/* Tag alineado a la izquierda */}
										<Tag
											// color={
											// 	item.etiqueta === "COMUNICADO"
											// 		? "blue"
											// 		: item.etiqueta === "PUBLICACION"
											// 		? "green"
											// 		: "yellow"
											// }

											color="blue"
											style={{ alignSelf: "center", marginRight: "16px" }}
										>
											{item.etiqueta}
										</Tag>
									</div>
								</div>
								<div>
									<p style={{ margin: "8px 0 0 0" }}>
										{" "}
										{/* Mensaje alineado con texto */}
										{item.mensaje}
									</p>
								</div>
								<br></br>
								<div>
									{item.imagenes && item.imagenes.length > 0 ? (
										item.imagenes.length > 1 ? (
											<Carousel autoplay>
												{item.imagenes.map((imagen, index) => (
													<div key={index}>
														<img
															width="100%"
															height={380}
															alt={`imagen-${index}`}
															src={imagen}
															style={{ objectFit: "contain" }}
														/>
													</div>
												))}
											</Carousel>
										) : (
											<img
												width="100%"
												height={380}
												alt="imagen"
												src={item.imagenes[0]}
												style={{ objectFit: "contain" }}
											/>
										)
									) : null}
								</div>
							</List.Item>
						</Skeleton>
					</List.Item>
				)}
			/>
		</div>
	);
};

const Navegacion = () => {
	return (
		<Breadcrumb style={{ marginBottom: "5px" }}>
			<Breadcrumb.Item>
				<HomeOutlined />
			</Breadcrumb.Item>
			<Breadcrumb.Item>
				Tareas Pendientes
			</Breadcrumb.Item>
		</Breadcrumb>
	)
}

const Container = styled.div`
	//border: 1px solid #ccc;
	flex: 1 1 25%;
	margin: 10px;
`;

const Titulo = styled.div`
	flex: 1 1 20%;
	padding: 10px;
	border-bottom: 1px solid #ccc;
`;

const Items = styled.div`
	padding: 10px;
	overflow: auto;
	max-height: calc(100% - 65px);
	background-color: white;
	border: 1px solid #ccc;
`;

export default ListaPublicaciones;
