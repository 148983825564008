import React, { useEffect, useState } from 'react';
import { useAtom } from "jotai";
import { Button, Checkbox, DatePicker, Form, message, Modal, Select, Upload } from 'antd';
import { useModalSubirDocumento } from '../hooks';
import { loadingCargaAtom } from "../ConsultaDocumentoStore";
import {
    UploadOutlined
} from "@ant-design/icons"

const ModalSubirDocumento = () => {
    const [form] = Form.useForm();
    const [archivos, setArchivos] = useState([]);
    const {
        open,
        tiposDocumento,
        colaboradores,
        loadingCarga,
        setFormInstance,
        onClickBtnSubirDocumento,
        onSearchColaboradores,
        fetchTiposDocumento,
        cerrarModal,
    } = useModalSubirDocumento();

    useEffect(() => {
        const inicializar = () => {
            if (open) {
                fetchTiposDocumento();
                setFormInstance(form);
            }
        }
        inicializar();
    }, [open]);


    const propsUpload = {
        onRemove: (file) => {
            setArchivos([]);
            return {
                archivos: [],
            };
        },
        beforeUpload: (file) => {
            if (file.type !== "application/pdf") {
                message.error(`${file.name} no es un archivo permitido (.docx o .pdf)`);
            } else {
                setArchivos(file);
                return false;
            }
        },
        archivos,
    };

    return (
        <Modal
            title="Subir Documento"
            open={open}
            onOk={onClickBtnSubirDocumento}
            okText="Subir Documento"
            confirmLoading={loadingCarga}
            onCancel={cerrarModal}
            cancelButtonProps={{ disabled: loadingCarga }}
            destroyOnClose={true}
            maskClosable={false}
        // afterClose={() => form.resetFields()}
        >
            <Form
                form={form}
                layout="vertical"
            >

                <Form.Item
                    label="Seleccione un periodo"
                    name={"periodo"}
                    rules={[
                        {
                            required: true,
                            message: "Debe ingresar el periodo. ",
                        },
                    ]}
                >
                    <DatePicker allowClear={false} format={"MM/YYYY"} picker="month" style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                    name="colaboradorId"
                    label="Colaborador"
                    rules={[
                        {
                            required: true,
                            message: "Debe seleccionar un colaborador. ",
                        },
                    ]}
                >
                    <Select
                        style={{ width: "100%" }}
                        allowClear
                        showSearch
                        placeholder="Ingrese el nombre del colaborador"
                        onSearch={onSearchColaboradores}
                        options={colaboradores}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </Form.Item>

                <Form.Item
                    name="tipoDocumentoId"
                    label="Tipo Documento"
                    rules={[
                        {
                            required: true,
                            message: "Debe seleccionar el tipo de documento. ",
                        },
                    ]}
                >
                    <Select
                        showSearch
                        allowClear
                        options={tiposDocumento}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </Form.Item>

                <Form.Item
                    name="requiereFirmaColaborador"
                    label="¿Firma colaborador requerido?"
                    rules={[
                        {
                            required: true,
                            message: "Debe indicar si se requiere firma del colaborador.",
                        },
                    ]}
                >
                    <Select
                        options={[
                            {
                                label: "NO",
                                value: false
                            },
                            {
                                label: "SI",
                                value: true
                            }
                        ]}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </Form.Item>

                {/* <Form.Item
                    name="requiereFirmaColaborador"
                    valuePropName="checked"
                >
                    <Checkbox>¿Se requiere firma del colaborador?</Checkbox>
                </Form.Item> */}

                <Form.Item
                    name="documento"
                    label="Documento"
                    valuePropName="documento"
                    rules={[{
                        required: true,
                        message: "Debe seleccionar un archivo.",
                    }]}>
                    <Upload
                        {...propsUpload}
                        maxCount={1}
                        accept={["application/pdf"]}>
                        <Button icon={<UploadOutlined />}>
                            Subir Documento
                        </Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal >
    );
};

export default ModalSubirDocumento;