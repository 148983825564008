import { HttpService } from "../HttpService";
import { URL } from "enum";
const URL_API = URL.BASE;

const PublicacionService = {
	async listar() {
		const respuesta = await HttpService(URL_API).get("/comunicaciones-cultura/publicaciones");
		return respuesta.data;
	},
};
export default PublicacionService;
