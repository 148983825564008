import React from "react";
import styled from "@emotion/styled";
import { Calendario, Filtros } from "./componentes";
import { Provider as JotaiProvider } from "jotai";

const CalendarioPage = () => {
	return (
		<JotaiProvider>
			<Container>
				<Filtros />
				<Calendario />
			</Container>
		</JotaiProvider>
	);
};

const Container = styled.div`
	display: flex;
	height: 100%;
	min-height: 100%;
	padding: 20px 10px;
	flex-direction: column;
`;

export default CalendarioPage;
