import React, { useState } from "react";
import styled from "@emotion/styled";
import "./Calendario.css";
import { useAtom } from "jotai";
import { colaboradoresAtom, fechaSeleccionadaAtom, totalDiasAtom } from "../../CalendarioStore";
import { useEffect } from "react";
import moment from "moment";
import { JefeInmediatoService } from "services/VacacionesService";
import { Tooltip } from "antd";

const Calendario = () => {
	const [fechaSeleccionada] = useAtom(fechaSeleccionadaAtom);
	const [colaboradores, setColaboradores] = useAtom(colaboradoresAtom);

	const cargarColaboradores = async () => {
		const response = await JefeInmediatoService.listarColaboradores({
			mes: fechaSeleccionada.month() + 1,
			anio: fechaSeleccionada.year(),
		});
		setColaboradores(response.colaboradores);
	};

	useEffect(() => {
		cargarColaboradores();
	}, [fechaSeleccionada]);

	return (
		<Container>
			<Titulo />
			{colaboradores &&
				colaboradores.map((colaborador) => <Fila key={colaborador.id} colaborador={colaborador} />)}
		</Container>
	);
};

const Titulo = () => {
	const [fechaSeleccionada] = useAtom(fechaSeleccionadaAtom);
	const [totalDays] = useAtom(totalDiasAtom);
	const [days, setDays] = useState(Array.from({ length: totalDays }, (_, index) => index + 1));

	const actualizarDias = () => {
		setDays(Array.from({ length: totalDays }, (_, index) => index + 1));
	};

	useEffect(() => {
		actualizarDias();
	}, [totalDays]);

	return (
		<div style={{ height: "35px", display: "flex" }}>
			<div style={{ width: "25%", border: "1px solid #ccc", borderRight: "0px", borderBottom: "0px" }}>
				Nombres y Apellidos
			</div>
			<div style={{ width: "75%" }}>
				<div className="fila" style={{ borderBottom: "0px" }}>
					{days.map((day) => (
						<div key={day} className="day">
							<span>
								{moment({
									year: fechaSeleccionada.year(),
									month: fechaSeleccionada.month(),
									date: day,
								})
									.format("dd")
									.toUpperCase()}
							</span>
							<span>{day}</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

const Fila = ({ colaborador }) => {
	const [totalDays] = useAtom(totalDiasAtom);
	const [days, setDays] = useState(Array.from({ length: totalDays }, (_, index) => index + 1));

	const actualizarDias = () => {
		setDays(Array.from({ length: totalDays }, (_, index) => index + 1));
	};

	useEffect(() => {
		actualizarDias();
	}, [totalDays]);

	return (
		<div style={{ height: "35px", display: "flex" }}>
			<div style={{ width: "25%", border: "1px solid #ccc", borderRight: "0px" }}>
				{colaborador.nombreCompleto}
			</div>
			<div style={{ width: "75%" }}>
				<div className="fila">
					{colaborador?.vacaciones &&
						colaborador.vacaciones?.map((solicitud) => (
							<Tooltip title={solicitud.nombre} placement="top">
								<div
									key={solicitud.id}
									className="highlight"
									style={{
										left: `calc( (${solicitud.diaInicio} - 1 )  * (100% / ${totalDays}))`,
										width: `calc(${solicitud.diasSolicitados} * (100% / ${totalDays}))`,
										backgroundColor: solicitud.color ? solicitud.color : "lightblue"
									}}
								>

								</div>
							</Tooltip>
						))}

					{days.map((day) => (
						<div key={day} className="day" />
					))}
				</div>
			</div>
		</div>
	);
};

const Container = styled.div`
	width: 100%;
`;

const ContainerDias = styled.div`
	width: 75%;
	// padding: 10px 10px;
`;

const ContainerColaboradores = styled.div`
	width: 25%;
	border: 1px solid #ccc;
`;

export default Calendario;
