import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Avatar } from 'antd';
import { useAtom } from 'jotai';
import { PortalColaboradorService } from 'services/PortalColaboradorService';
import { datosPerfilAtom } from 'storage/MiPortalStorage';
import { UserOutlined } from "@ant-design/icons"
import { FcAlarmClock, FcConferenceCall, FcFinePrint, FcHighPriority, FcTodoList } from "react-icons/fc";
import { Boton } from '../common';
import { ListaPublicaciones } from '../ListaPublicaciones';


const MiPortalPageWeb = () => {
    return (
        <Container>
            <SeccionIzquierda>
                <Perfil />
                <Acciones />
            </SeccionIzquierda>

            <ContainerInformacion>
                {/* Sección Noticias / Información */}
                <ListaPublicaciones></ListaPublicaciones>
            </ContainerInformacion>
        </Container>
    );
};

const Acciones = () => {
    return (
        <div style={{ marginTop: "10px" }}>
            <Boton
                icono={<FcHighPriority size="1.5em" />}
                nombre="Documentos Pendientes"
            />

            <Boton
                icono={<FcFinePrint size="1.5em" />}
                nombre="Mis Documentos"
            />

            <Boton
                icono={<FcAlarmClock size="1.5em" />}
                nombre="Ausencias y Vacaciones"
            />

            <Boton
                icono={<FcTodoList size="1.5em" />}
                nombre="Bandeja de Tareas"
            />

            <Boton
                icono={<FcConferenceCall size="1.5em" />}
                nombre="Solicitud de Personal"
            />
        </div>
    )
}

const Perfil = () => {
    const [datosPerfil, setDatosPerfil] = useAtom(datosPerfilAtom);

    useEffect(() => {
        const cargarDatosIniciales = () => {
            PortalColaboradorService.obtenerDatosPerfil()
                .then(response => {
                    setDatosPerfil(response);
                })
                .catch(error => {
                    console.error("Error al obtener la foto", error)
                })
        }
        cargarDatosIniciales();
    }, []);

    const FotoPersonal = () => {
        if (datosPerfil?.urlFoto) {
            return <Avatar size={64} src={datosPerfil.urlFoto} />
        }

        return (
            <Avatar size={64}
                style={{
                    backgroundColor: '#87d068',
                }}
                icon={<UserOutlined />}
            />
        )
    }

    const SeccionNombre = styled.span`
        margin-top: 10px;
        font-size: 12px;
        font-weight: bold;
        color: #1678C1;
    `;

    const SeccionCargo = styled.span`
        margin-top: 5px;
        font-size: 12px;
        color: #878c8f;
    `;

    const Container = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 0px;
        background: white;
        border-radius: 5px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02);
    `

    return (
        <Container>
            <FotoPersonal />
            <SeccionNombre>
                {datosPerfil?.nombreCompleto}
            </SeccionNombre>
            <SeccionCargo>
                {datosPerfil?.cargo}
            </SeccionCargo>
        </Container>
    )

}

const Container = styled.div`
    width: 100%;
    display: flex;
`

const SeccionIzquierda = styled.div`
    width: 25%;
`
const ContainerInformacion = styled.div`
    width: 75%;
    background: white;
    margin-left: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02);
`

export default MiPortalPageWeb;